<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Editar vehiculo</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!-- Model -->
        <vs-input @blur="document.model= trimString(document.model)" label="Modelo*" v-model="document.model"
                  class="w-full" name="model"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('model')">{{ errors.first('model') }}</span>

        <!-- License plate -->
        <vs-input @blur="document.licensePlate= trimString(document.licensePlate)" label="Placa*"
                  v-model="document.licensePlate" class="mt-5 w-full" name="licensePlate"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('licensePlate')">{{ errors.first('licensePlate') }}</span>

        <!-- Driver name -->
        <vs-input @blur="document.driverName= trimString(document.driverName)" label="Nombre de conductor*"
                  v-model="document.driverName" class="mt-5 w-full" name="driverName"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('driverName')">{{ errors.first('driverName') }}</span>

        <!-- Driver document -->
        <vs-input @blur="document.driverDocument= trimString(document.driverDocument)" label="Documento de conductor*"
                  v-model="document.driverDocument" class="mt-5 w-full" name="driverDocument"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('driverDocument')">{{ errors.first('driverDocument') }}</span>
      </div>
    </component>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'

import { db, auth, FieldValue } from '@/firebase/firebaseConfig'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar
  },
  mixins: [trimString],
  data () {
    return {
      document: {},
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.document = {
          ...this.data
        }
        this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    async submitData () {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          this.progress = true
          // Update vehicle
          let obj = {
            ...this.document
          }
          await db.collection('vehicles').doc(this.document.id).update({
            ...obj,
            uid: auth.currentUser.uid,
            updatedAt: FieldValue.serverTimestamp()
          })
          this.$emit('closeSidebar')
          this.$emit('update', obj)
          this.$vs.notify({
            color: 'success',
            title: 'Vehiculo',
            text: 'Vehiculo actualizado correctamente.'
          })
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.progress = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
